<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <div class="main-page-content">
        <div>
          <el-row :gutter="12">
            <el-col :xl="6" :lg="8" :md="12" :sm="24" v-for="(menu ,k) in menus" :key="k" style="margin-bottom: 10px">
              <el-card shadow="hover" class="box-card" v-if="userPermissions.indexOf(menu.permission_name) !== -1">
                <el-link type="primary" @click="goToPage(menu.url)">
                  <i :class="`iconfont ${menu.icon}`"></i>
                  &nbsp;&nbsp;{{ menu.display_name }}&nbsp;&nbsp;
                </el-link>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeaderLayout from "@/layouts/PageHeaderLayout";

export default {
  name: "PerformanceData",
  components: { PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  data() {
    return {
      menus: [
        { icon: 'icon-zhangfen', display_name: '涨粉绩效', url: '/performance_data/trend', permission_name: 'performance_trend_list' },
        { icon: 'icon-yingshou', display_name: '营收绩效', url: '/performance_data/revenue', permission_name: 'performance_revenue_list' },
        { icon: 'icon-yingshou', display_name: '核算月份', url: '/performance_data/revenue', permission_name: 'performance_revenue_list' },
        { icon: 'icon-yingshou', display_name: '奖金分配', url: '/performance_data/revenue', permission_name: 'performance_revenue_list' },
      ]
    }
  },
  methods: {
    goToPage(routeName) {
      this.$router.push({ path: routeName })
    },
  }
}
</script>

<style scoped>

</style>
